import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Metadata } from 'src/components/AnnotationImage';

interface AnnotationState {
  metadata?: Metadata;
}

const initialState: AnnotationState = {
  metadata: {
    height: window.innerWidth,
    width: window.innerHeight,
    scaleX: 1,
    scaleY: 1,
    imageWidth: 0,
    imageHeight: 0,
  },
};

const annotationSlice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    setMetadata(state: AnnotationState, action: PayloadAction<Metadata>): void {
      state.metadata = action.payload;
    },
  },
});

export const { setMetadata } = annotationSlice.actions;
export const { reducer } = annotationSlice;
