import { Box, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { FiberManualRecord, Info, NotificationImportant, Settings, ShoppingCart } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { Notification } from 'src/types/notification';

const NotificationItem: React.FC<{ notification: Notification; onClick: (id: string) => void }> = ({
  notification,
  onClick,
}) => {
  const handleClickNotification = () => {
    onClick?.(notification.id);
  };
  const renderNotificationIcon = (type) => {
    switch (type) {
      case 'info':
        return <Info sx={{ color: '#1976D2' }} />;
      case 'order':
        return <ShoppingCart sx={{ color: '#D32F2F' }} />;
      case 'system':
        return <Settings sx={{ color: '#FF9800' }} />;
      default:
        return <NotificationImportant />;
    }
  };

  return (
    <ListItem
      button
      sx={{
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: notification.isRead ? 'white' : '#f5f5f5',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
      }}
      onClick={handleClickNotification}
    >
      <ListItemIcon
        sx={{
          minWidth: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#555',
        }}
      >
        {renderNotificationIcon(notification.type)}
      </ListItemIcon>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
          {notification.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#555',
            marginTop: 0.5,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {notification.description}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: '#888',
            marginTop: 0.5,
            display: 'block',
          }}
        >
          {moment(notification.createdDate).local().format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      </Box>
      <ListItemIcon
        sx={{
          minWidth: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!notification.isRead && <FiberManualRecord sx={{ color: 'green', fontSize: 14 }} />}
      </ListItemIcon>
    </ListItem>
  );
};

export default NotificationItem;
