import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';

import toast from 'react-hot-toast';
import { orderAPIV2 } from 'src/api/ordersAPI-v2';
import { userApi } from 'src/api/userApi';
import { AppThunk } from 'src/store';
import { DataPaging, IPagination } from 'src/types/data';
import { DraftOrderInput, Order, OrderFilterValues, OrderParams, OrderV2OverviewData } from 'src/types/order';
import { UserByPosition } from 'src/types/users';
import { getCustomerById } from './customers';

export type OrderState = {
  data: DataPaging<Order[]>;
  params: OrderParams;
  filter: OrderFilterValues;
  currentOrder: Order | null;
  overviewData: OrderV2OverviewData;
  staffs: UserByPosition[];
};

export const initialState: OrderState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
  currentOrder: null,
  overviewData: {},
  staffs: [],
};

const ordersV2Slice = createSlice({
  name: 'orders-v2',
  initialState,
  reducers: {
    setDataOrderV2(
      state,
      action: PayloadAction<{ data: DataPaging<Order[]> | null; params: OrderParams; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataOrderV2(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
    setCurrentOrderV2(state, action: PayloadAction<Order>) {
      state.currentOrder = action.payload;
    },
    setOverviewDataV2(state, action: PayloadAction<OrderV2OverviewData>) {
      state.overviewData = action.payload;
    },
    setStaffs(state, action: PayloadAction<UserByPosition[]>) {
      state.staffs = action.payload;
    },
  },
});
export const {
  reducer,
  actions: { setDataOrderV2, resetDataOrderV2, setCurrentOrderV2, setOverviewDataV2, setStaffs },
} = ordersV2Slice;

export default ordersV2Slice;

export const getListOrderV2 =
  ({
    filter,
    pagination,
    params,
    isResetData = true,
    isSavedToRedux = true,
    onSuccess,
  }: {
    filter?: OrderFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: OrderParams;
    isResetData?: boolean;
    isSavedToRedux?: boolean;
    onSuccess?: (res: Order[]) => void;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      if (isResetData) {
        dispatch(setDataOrderV2({ data: initialState.data, filter, params }));
      }
      const filterValues = {
        limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
        offset: pagination.rowsPerPage * pagination.page || 0,
        searchValue: params.searchValue,
        orderBy: params.orderBy,
        orderDirection: params.orderDirection,
        postcode: params.postcode,
        status: params.status,
        type: params.type,
      };
      const data = await orderAPIV2.getList(filterValues);
      const orderData = {
        data: {
          data: data.orders,
          pagination: {
            ...pagination,
            count: data.total,
          },
        },
        filter,
        params,
      };

      if (data && isSavedToRedux) {
        dispatch(setDataOrderV2(orderData));
      }
      onSuccess?.(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

export const copyOrderAndRefetchData =
  ({
    onSuccess,
    onError,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
    onError?: () => void;
  }): AppThunk =>
  async (dispatch, getState): Promise<any> => {
    try {
      const response = await orderAPIV2.copyOrder(id);

      const state = getState();

      if (response.success) {
        onSuccess?.();
        dispatch(
          getListOrderV2({
            filter: state.orderV2.filter,
            pagination: state.orderV2.data.pagination,
            params: state.orderV2.params,
            isResetData: false,
          }),
        );
        dispatch(
          getOrderV2OverviewData({
            type: state.orderV2.filter?.type?.value,
          }),
        );
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const deleteOrderAndRefetchData =
  ({
    onSuccess,
    onError,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
    onError?: () => void;
  }): AppThunk =>
  async (dispatch, getState): Promise<any> => {
    try {
      const response = await orderAPIV2.deleteOrder(id);

      const state = getState();

      if (response.success) {
        onSuccess?.();
        dispatch(
          getListOrderV2({
            filter: state.orderV2.filter,
            pagination: state.orderV2.data.pagination,
            params: state.orderV2.params,
            isResetData: false,
          }),
        );
        dispatch(
          getOrderV2OverviewData({
            type: state.orderV2.filter?.type?.value,
          }),
        );
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const getOrderV2OverviewData =
  ({ type }: { type: string }): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      dispatch(setOverviewDataV2({}));

      const response = await orderAPIV2.getOrderOverView({ type });

      if (response.success) {
        dispatch(setOverviewDataV2(response.overview));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const createOrderV2 =
  ({
    values,
    onSuccess,
    onError,
  }: {
    values: DraftOrderInput;
    onSuccess?: (res) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await orderAPIV2.createAxiosOrder(values);
      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const getOrderById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await orderAPIV2.getAxiosOrderById(id);

      const orderData = response.data;

      if (response.success) {
        disptach(setCurrentOrderV2(orderData));
        disptach(getCustomerById(orderData.userId));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

export const deleteOrderV2ById =
  ({
    onSuccess,
    onError,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await orderAPIV2.deleteOrder(id);

      if (response.success) {
        onSuccess?.();
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const cancelOrderV2ById =
  ({
    onSuccess,
    onError,
    id,
  }: {
    id: string;

    onSuccess?: () => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await orderAPIV2.cancelOrder(id);

      if (response.success) {
        onSuccess?.();
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const updateOrderV2 =
  ({
    values,
    onSuccess,
    onError,
    id,
  }: {
    id: string;
    values: any;
    onSuccess?: (res: Order) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await orderAPIV2.updateAxiosOrder(id, values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const updateOrderV2Step =
  ({
    values,
    onSuccess,
    onError,
    id,
    step,
  }: {
    id: string;
    values: any;
    step: string;
    onSuccess?: (res: Order) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await orderAPIV2.updateAxiosOrderStep({
        values,
        id,
        step,
      });

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const getStaffListByPosition =
  (position: string): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      const response = await userApi.getAxiosStaffListByPosition({
        limit: 999,
        offset: 0,
        position,
      });

      if (response.success) {
        dispatch(setStaffs(response.users));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const exportOrderById =
  ({
    onSuccess,
    orderId,
    type,
    orderNumber,
  }: {
    orderId: string;
    type: 'pdf' | 'excel';
    onSuccess: () => void;
    orderNumber: number;
  }): AppThunk =>
  async () => {
    try {
      await orderAPIV2.exportAxiosOrderById(orderId, type, orderNumber);
      onSuccess();
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

export const forceFinishOrderV2ById =
  ({ onSuccess, orderId }: { orderId: string; onSuccess: () => void }): AppThunk =>
  async () => {
    try {
      await orderAPIV2.forceFinishOrderById(orderId);
      onSuccess();
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
