import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { orderApi } from 'src/api/orderApi';
import { Order, OrderFilterType, OrderStatus, DraftOrderInput } from 'src/types/order';
import type { AppThunk } from '../store';
import objFromArray from '../utils/objFromArray';
import { INIT_PAGINATION } from 'src/constants';

interface OrderState {
  orders: {
    byId: Record<string, Order>;
    allIds: string[];
  };
  pagination: typeof INIT_PAGINATION;
  status: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  targetOrderId: string | null;
  total: number;
  filter: Partial<OrderFilterType>;
}

const initialState: OrderState = {
  orders: {
    byId: {},
    allIds: [],
  },
  pagination: INIT_PAGINATION,
  filter: {},
  status: 'idle',
  total: 0,
  targetOrderId: null,
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrders(state: OrderState, action: PayloadAction<{ orders: Order[]; total: number }>): void {
      const { orders, total } = action.payload;
      state.status = 'success';
      state.total = total;
      state.orders.byId = objFromArray(orders);
      state.orders.allIds = Object.keys(state.orders.byId);
    },
    getOrder(state: OrderState, action: PayloadAction<Order>): void {
      const order = action.payload;
      state.status = 'success';
      state.targetOrderId = order.id;
      state.orders.byId[order.id] = { ...state.orders.byId[order.id], ...order };
    },
    updateFilter(state: OrderState, action: PayloadAction<OrderFilterType>): void {
      const filter = action.payload;
      state.filter = filter;
    },
    updateStatus(
      state: OrderState,
      action: PayloadAction<'idle' | 'loading' | 'success' | 'error' | 'updating'>,
    ): void {
      state.status = action.payload;
    },
    updateOrderStatus(state: OrderState, action: PayloadAction<{ orderId: string; status: OrderStatus }>): void {
      const { orderId, status } = action.payload;
      state.orders.byId[orderId].status = status;
    },
    updateOrderAdminShow(state: OrderState, action: PayloadAction<{ orderId: string; adminShow: boolean }>): void {
      const { orderId, adminShow } = action.payload;
      state.orders.byId[orderId].adminShow = adminShow;
    },
  },
});

export const { reducer } = slice;

export const getOrders =
  (filter?: OrderFilterType): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.updateStatus('loading'));
    dispatch(slice.actions.updateFilter(filter));
    const { orders, total, success } = await orderApi.getOrders(filter);
    if (success) {
      dispatch(slice.actions.getOrders({ orders, total }));
    } else {
      dispatch(slice.actions.updateStatus('error'));
    }
  };

export const getOrder =
  (id: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.updateStatus('loading'));
    const { order, success } = await orderApi.getOrder(id);
    if (!success) {
      dispatch(slice.actions.updateStatus('error'));
    } else {
      dispatch(slice.actions.getOrder(order));
      dispatch(slice.actions.updateStatus('success'));
    }
  };

export const updateOrderStatus =
  ({ status, orderId, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await orderApi.updateOrderStatus(orderId, status);
    if (success) {
      onComplete?.({ success });
      dispatch(slice.actions.updateOrderStatus({ orderId, status }));
    } else {
      onError('Cannot update user');
    }
  };

export const updateOrderAdminShow =
  ({ adminShow, orderId, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const res = await orderApi.updateOrderAdminShow(orderId, adminShow);
    const { success } = res;
    if (success) {
      onComplete?.({ success });
      dispatch(slice.actions.updateOrderAdminShow({ orderId, adminShow }));
    } else {
      onError('Cannot update user');
    }
  };

export const deleteOrder =
  ({ orderId, onComplete, onError }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { order } = getState();
    const { filter } = order as unknown as { filter: OrderFilterType };
    const res = await orderApi.deleteOrder(orderId);
    const { success } = res;
    if (success) {
      onComplete?.({ success });
      dispatch(getOrders(filter));
    } else {
      onError('Cannot delete order');
    }
  };

export const createOrder =
  ({
    values,
    onSuccess,
    onError,
  }: {
    values: DraftOrderInput;
    onSuccess?: (res) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await orderApi.createAxiosOrder(values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export default slice;
