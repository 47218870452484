import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';

import { AppThunk } from 'src/store';
import { FilterValues, Params } from 'src/types/shared';
import { DataPaging, IPagination } from 'src/types/data';
import { PackagingUnit } from 'src/types/packagingUnit';
import { packagingUnitApi } from 'src/api/packagingUnitApi';

export type PackagingUnitState = {
  data: DataPaging<PackagingUnit[]>;
  params: Params;
  filter: FilterValues;
  currentPackagingUnit: PackagingUnit | null;
};

export const initialState: PackagingUnitState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
  currentPackagingUnit: null,
};

const packagingUnitSlice = createSlice({
  name: 'packagingUnit',
  initialState,
  reducers: {
    setDataPackagingUnit(
      state,
      action: PayloadAction<{ data: DataPaging<PackagingUnit[]> | null; params: Params; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataPackagingUnit(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
    setCurrentPackagingUnit(state, action: PayloadAction<PackagingUnit>) {
      state.currentPackagingUnit = action.payload;
    },
  },
});
export const {
  reducer,
  actions: { setDataPackagingUnit, resetDataPackagingUnit, setCurrentPackagingUnit },
} = packagingUnitSlice;

export default packagingUnitSlice;

export const getListPackagingUnit =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: FilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: Params;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
    };
    const data = await packagingUnitApi.getList(filterValues as any);

    if (data) {
      dispatch(
        setDataPackagingUnit({
          data: {
            data: data.packagingUnit,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

type IConfig = { onSuccess?: (res?: DataPaging<PackagingUnit[]>) => void; isSaveDataToRedux?: boolean };

export const getAllPackagingUnit =
  (
    {
      filter,
      pagination,
      params,
    }: {
      filter?: FilterValues;
      pagination?: Omit<IPagination, 'count'>;
      params?: Params;
    },
    config?: IConfig,
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      ...params,
      limit: pagination.rowsPerPage,
      offset: pagination.rowsPerPage * pagination.page || 0,
    };
    const data = await packagingUnitApi.getList(filterValues as any);

    const { onSuccess, isSaveDataToRedux = true } = config;

    if (data) {
      const returnedData = {
        data: {
          data: data.packagingUnit,
          pagination: {
            ...pagination,
            count: data.total,
          },
        },
        filter,
        params,
      };
      if (isSaveDataToRedux) {
        dispatch(setDataPackagingUnit(returnedData));
      }
      onSuccess?.(returnedData.data);
    }
  };

export const createPackagingUnit =
  ({
    values,
    onSuccess,
    onError,
  }: {
    values: PackagingUnit;
    onSuccess?: (res: PackagingUnit) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await packagingUnitApi.createPackagingUnit(values);
      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const updatePackagingUnit =
  ({
    values,
    onSuccess,
    onError,
    id,
  }: {
    id: string;
    values: PackagingUnit;
    onSuccess?: (res: PackagingUnit) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await packagingUnitApi.updatePackagingUnit(id, values);

      if (response.success) {
        onSuccess?.(response.packagingUnit);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const deletePackagingUnit =
  ({ onSuccess, id }: { id: string; onSuccess?: () => void }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await packagingUnitApi.deletePackagingUnit(id);

      if (response.success) {
        onSuccess?.();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

export const getPackagingUnitById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await packagingUnitApi.getPackagingUnitById(id);

      if (response.success) {
        disptach(setCurrentPackagingUnit(response.data));
      }
    } catch (error) {
      console.log('error', error);
    }
  };
