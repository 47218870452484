import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';

import { AppThunk } from 'src/store';
import { FilterValues, Params } from 'src/types/shared';
import { DataPaging, IPagination } from 'src/types/data';
import { Supplier } from 'src/types/supplier';
import { supplierApi } from 'src/api/supplierApi';
import { Product, ProductParams } from 'src/types/product';
import { ProductFilterValues } from 'src/pages/product/list/FilterBar';

export type SupplierState = {
  data: DataPaging<Supplier[]>;
  params: Params;
  filter: FilterValues;
  currentSupplier: Supplier | null;
  supplierProducts: {
    data: DataPaging<Product[]>;
    params: ProductParams;
    filter: ProductFilterValues;
  };
};

export const initialState: SupplierState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  supplierProducts: {
    data: {
      data: [],
      pagination: INIT_PAGINATION,
      mapping: {},
    },
    params: {},
    filter: {},
  },
  params: {},
  filter: {},
  currentSupplier: null,
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setDataSupplier(
      state,
      action: PayloadAction<{ data: DataPaging<Supplier[]> | null; params: Params; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataSupplier(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
    setCurrentSupplier(state, action: PayloadAction<Supplier>) {
      state.currentSupplier = action.payload;
    },
    setDataSupplierProduct(
      state,
      action: PayloadAction<{ data: DataPaging<Product[]> | null; params: ProductParams; filter: any }>,
    ) {
      state.supplierProducts.data = action.payload.data;
      state.supplierProducts.filter = action.payload.filter;
      state.supplierProducts.params = action.payload.params;
    },
    resetDataSupplierProduct(state) {
      state.supplierProducts.data = initialState.supplierProducts.data;
      state.supplierProducts.params = initialState.supplierProducts.params;
      state.supplierProducts.filter = initialState.supplierProducts.filter;
    },
  },
});
export const {
  reducer,
  actions: { setDataSupplierProduct, resetDataSupplierProduct, setDataSupplier, resetDataSupplier, setCurrentSupplier },
} = supplierSlice;

export default supplierSlice;

export const getListSupplier =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: FilterValues;
    pagination?: Omit<IPagination, 'count'>;
    params?: Params;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
    };
    const data = await supplierApi.getList(filterValues as any);

    if (data) {
      dispatch(
        setDataSupplier({
          data: {
            data: data.supplier,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

type IConfig = { onSuccess?: (res?: DataPaging<Supplier[]>) => void; isSaveDataToRedux?: boolean };

export const getAllSupplier =
  (
    {
      filter,
      pagination,
      params,
    }: {
      filter?: FilterValues;
      pagination?: Omit<IPagination, 'count'>;
      params?: Params;
    },
    config?: IConfig,
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      ...params,
      limit: pagination.rowsPerPage,
      offset: pagination.rowsPerPage * pagination.page || 0,
    };
    const data = await supplierApi.getList(filterValues as any);

    const { onSuccess, isSaveDataToRedux = true } = config;

    if (data) {
      const returnedData = {
        data: {
          data: data.supplier,
          pagination: {
            ...pagination,
            count: data.total,
          },
        },
        filter,
        params,
      };
      if (isSaveDataToRedux) {
        dispatch(setDataSupplier(returnedData));
      }
      onSuccess?.(returnedData.data);
    }
  };

export const createSupplier =
  ({
    values,
    onSuccess,
    onError,
  }: {
    values: Supplier;
    onSuccess?: (res: Supplier) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await supplierApi.createSupplier(values);
      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const updateSupplier =
  ({
    values,
    onSuccess,
    onError,
    id,
  }: {
    id: string;
    values: Supplier;
    onSuccess?: (res: Supplier) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await supplierApi.updateSupplier(id, values);

      if (response.success) {
        onSuccess?.(response.supplier);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const deleteSupplier =
  ({ onSuccess, id }: { id: string; onSuccess?: () => void }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await supplierApi.deleteSupplier(id);

      if (response.success) {
        onSuccess?.();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

export const getSupplierById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await supplierApi.getSupplierById(id);

      if (response.success) {
        disptach(setCurrentSupplier(response.data));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

export const getSupplierProductById =
  (
    id,
    {
      filter,
      pagination,
      params,
    }: {
      filter?: ProductFilterValues;
      pagination: Omit<IPagination, 'count'>;
      params: ProductParams;
    },
  ): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const filterValues = {
        ...params,
        id,
        limit: pagination.rowsPerPage,
        offset: pagination.rowsPerPage * pagination.page || 0,
      };
      const response = await supplierApi.getProductListBySupplierId(filterValues);
      if (response.data) {
        disptach(
          setDataSupplierProduct({
            data: {
              data: response.data,
              pagination: {
                ...pagination,
                count: response?.total,
              },
            },
            params,
            filter,
          }),
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };
