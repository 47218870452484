import React from 'react';
import { Box, Typography } from '@material-ui/core';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { useTranslation } from 'react-i18next';

const EmptyNotification = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 140px)',
        textAlign: 'center',
        padding: 2,
        backgroundColor: '#f9f9f9',
      }}
    >
      <NotificationsOffIcon sx={{ fontSize: 64, color: '#bdbdbd', marginBottom: 2 }} />
      <Typography variant="h6" sx={{ color: '#333', fontWeight: 'bold', marginBottom: 1 }}>
        {t('notification-none')}
      </Typography>
      <Typography variant="body2" sx={{ color: '#757575' }}>
        {t('notification-none-message')}
      </Typography>
    </Box>
  );
};

export default EmptyNotification;
