import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { productAPI } from 'src/api/productAPI';

import { ProductCUFormValues } from 'src/components/product/CUForm';

import { INIT_PAGINATION } from 'src/constants';
import { ProductFilterValues } from 'src/pages/product/list/FilterBar';

import { AppThunk } from 'src/store';
import { DataPaging, IPagination, ResponseDataPaging } from 'src/types/data';
import { Product, ProductParams } from 'src/types/product';

export type ProductState = {
  data: DataPaging<Product[]>;
  params: ProductParams;
  filter: ProductFilterValues;
};

export const productInitialState: ProductState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
};

const productSlice = createSlice({
  name: 'product',
  initialState: productInitialState,
  reducers: {
    setDataProduct(
      state,
      action: PayloadAction<{ data: DataPaging<Product[]> | null; params: ProductParams; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataProduct(state, action) {
      state.data = productInitialState.data;
      state.params = productInitialState.params;
      state.filter = productInitialState.filter;
    },
  },
});
export const {
  reducer,
  actions: { setDataProduct, resetDataProduct },
} = productSlice;

export default productSlice;

export const getListProduct =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: ProductFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: ProductParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await productAPI.getList(params, pagination);
    const dataMapping = data.data.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {});

    if (data) {
      dispatch(
        setDataProduct({
          data: {
            ...data,
            pagination: {
              ...pagination,
              count: data.paging.total,
            },
            mapping: dataMapping,
          },
          filter,
          params,
        }),
      );
    }
  };

export const deleteProductById =
  (id: string, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productAPI.deleteById(id);
    if (data?.success) {
      callback?.();
    }
  };
export const createProduct =
  (params: ProductCUFormValues, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productAPI.post(params);

    if (data?.success) {
      callback?.();
    }
  };

export const updateProduct =
  (id: string, params: ProductCUFormValues, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productAPI.patch(id, params);
    if (data?.success) {
      callback?.();
    }
  };

export const checkProduct =
  (name: string, value: string, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productAPI.check(name, value);
    if (data?.success) {
      callback?.();
    }
  };

export const importExcel =
  (body: { file: File }, onSuccess?: () => void, onFailure?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productAPI.importExcel(body);
    if (data?.success) {
      onSuccess?.();
      return;
    }
    onFailure?.();
  };

export const getManufacturer = (): Promise<ResponseDataPaging<string[]>> => productAPI.getManufacturer();

export const getOriginCountry = (): Promise<ResponseDataPaging<string[]>> => productAPI.getOriginCountry();
