import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { userApi } from '../api/userApi';
import type { AppThunk } from '../store';
import type { User } from '../types/users';
import objFromArray from '../utils/objFromArray';
import { IOrder, OrderOverviewType } from '../types/order';
import { IAddress } from '../types/address';
import { IKpiType, IKpiTypeWithOrders } from 'src/types/kpi';
import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';
import { CustomerFilterValues, CustomerParams } from 'src/types/cutomers';
import { IPagination } from 'src/types/data';

interface UserState {
  users: {
    byId: Record<string, User>;
    allIds: string[];
  };
  pagination: typeof INIT_PAGINATION;
  status: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  orderStatus: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  addressStatus: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  kpiStatus: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  kpiOfMonthStatus: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  targetUserId: string;
  total: number;
  filter: Record<string, string>;
  params: CustomerParams;
  pageKey?: Record<string, unknown>;
  targetKpi: {
    kpi?: IKpiTypeWithOrders;
    year?: number;
    month?: number;
  };
  order: {
    orders: IOrder[];
    total: number;
    overview: OrderOverviewType;
  };
  kpi: {
    kpis: IKpiType[];
    total: number;
  };
  address: {
    data: IAddress[];
    total: number;
  };
}

const initialState: UserState = {
  users: {
    byId: {},
    allIds: [],
  },
  address: {
    data: [],
    total: 0,
  },
  pagination: INIT_PAGINATION,
  filter: {},
  params: {},
  status: 'idle',
  orderStatus: 'idle',
  addressStatus: 'idle',
  kpiStatus: 'idle',
  kpiOfMonthStatus: 'idle',
  total: 0,
  targetUserId: '',
  targetKpi: {
    kpi: null,
  },
  order: {
    orders: [],
    total: 0,
    overview: {
      totalOrder: 0,
      totalAmount: 0,
      totalAmountVat: 0,
    },
  },
  kpi: {
    kpis: [],
    total: 0,
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading(state: UserState, action: PayloadAction): void {
      state.status = 'loading';
    },
    error(state: UserState, action: PayloadAction): void {
      state.status = 'error';
    },
    setPageKey(state: UserState, action: PayloadAction<Record<string, unknown>>): void {
      state.pageKey = action.payload;
    },
    deleteUser(state: UserState, action: PayloadAction<{ userId: string }>): void {
      delete state.users.byId[action.payload.userId];
      state.status = 'success';
      state.users.allIds = Object.keys(state.users.byId);
    },
    getUsers(
      state: UserState,
      action: PayloadAction<{ users: User[]; pagination: typeof INIT_PAGINATION; params: CustomerParams; filter: any }>,
    ): void {
      const { users, pagination, params, filter } = action.payload;
      state.status = 'success';
      state.pagination = pagination;
      state.params = params;
      state.filter = filter;
      state.users.byId = objFromArray(users);
      state.users.allIds = Object.keys(state.users.byId);
    },
    getUser(state: UserState, action: PayloadAction<User>): void {
      const user = action.payload;
      state.status = 'success';
      state.targetUserId = user.id;
      state.users.byId[user.id] = user;
    },
    updateUser(state: UserState, action: PayloadAction<{ user: Partial<User>; userId: string }>): void {
      const { user, userId } = action.payload;
      const existingUser = state.users.byId[userId];
      if (existingUser) {
        state.users.byId[userId] = {
          ...existingUser,
          ...user,
        };
      }
    },
    updateFilter(state: UserState, action: PayloadAction<Record<string, string>>): void {
      const filter = action.payload;
      state.filter = filter;
    },
    createUser(state: UserState, action: PayloadAction<{ user: User }>): void {
      const { user } = action.payload;
      state.total += 1;
      state.users.byId[user.id] = user;
      state.users.allIds.push(user.id);
    },
    getUserOrder(
      state: UserState,
      action: PayloadAction<{
        userId: string;
        orders: IOrder[];
        overview: OrderOverviewType;
        total: number;
        pagination: typeof INIT_PAGINATION;
      }>,
    ): void {
      const { orders, pagination, overview, total, userId } = action.payload;
      state.targetUserId = userId;
      state.order = {
        orders,
        total,
        overview,
      };
      state.pagination = pagination;
    },
    setOrderStatus(state: UserState, action: PayloadAction<string>): void {
      const status = action.payload;
      state.orderStatus = status as 'idle' | 'loading' | 'success' | 'error' | 'updating';
    },
    getUserAddress(state: UserState, action: PayloadAction<{ userId: string; address: IAddress[] }>): void {
      const { userId, address } = action.payload;
      state.users.byId[userId].address = address;
    },
    setAddressStatus(state: UserState, action: PayloadAction<string>): void {
      const status = action.payload;
      state.addressStatus = status as 'idle' | 'loading' | 'success' | 'error' | 'updating';
    },
    resetTargetUser(state: UserState): void {
      state.targetUserId = '';
      state.addressStatus = 'idle';
      state.orderStatus = 'idle';
      state.order = {
        orders: [],
        total: 0,
        overview: {
          totalAmount: 0,
          totalAmountVat: 0,
          totalOrder: 0,
        },
      };
    },
    getUserKpis(state: UserState, action: PayloadAction<{ userId: string; kpis: IKpiType[]; total: number }>): void {
      const { kpis, total, userId } = action.payload;
      state.targetUserId = userId;
      state.kpi = {
        kpis,
        total,
      };
    },
    setUserKpiStatus(state: UserState, action: PayloadAction<string>): void {
      const status = action.payload;
      state.kpiStatus = status as 'idle' | 'loading' | 'success' | 'error' | 'updating';
    },
    getUserKpi(
      state: UserState,
      action: PayloadAction<{ userId: string; kpi: IKpiTypeWithOrders; month: number; year: number }>,
    ): void {
      const { kpi, userId, month, year } = action.payload;
      state.targetUserId = userId;
      state.targetKpi = {
        kpi,
        year,
        month,
      };
    },
    setUserKpiMonthStatus(state: UserState, action: PayloadAction<string>): void {
      const status = action.payload;
      state.kpiOfMonthStatus = status as 'idle' | 'loading' | 'success' | 'error' | 'updating';
    },
    deleteKpi(state: UserState, action: PayloadAction<string>): void {
      const kpiId = action.payload;
      state.kpi.kpis = state.kpi.kpis.filter((kpi) => kpi.id !== kpiId);
    },
  },
});

export const { reducer, actions } = slice;

export const deleteUser =
  ({ userId, onSuccess, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      await userApi.deleteUser(userId);
      onSuccess();
      dispatch(slice.actions.deleteUser({ userId }));
    } catch (err) {
      onError(err);
    }
  };

export const superDeleteUser =
  ({ userId, onSuccess, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(slice.actions.loading());
      await userApi.superDeleteUser(userId);
      onSuccess();
      dispatch(slice.actions.deleteUser({ userId }));
    } catch (err) {
      onError(err);
      dispatch(slice.actions.error());
    }
  };

export const approveUser =
  ({ userId, onSuccess, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const { user, success } = await userApi.approveUser(userId);
      if (success) {
        dispatch(slice.actions.updateUser({ userId, user }));
        onSuccess();
      } else {
        onError();
        dispatch(slice.actions.error());
      }
    } catch (err) {
      onError(err);
      dispatch(slice.actions.error());
    }
  };

export const getListUser =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: CustomerFilterValues;
    pagination?: Omit<IPagination, 'count'>;
    params?: CustomerParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      status: params.status,
      exceptRole: 'client',
    };
    const { users, total, success } = await userApi.getAxiosList(filterValues as any);

    if (success) {
      dispatch(
        slice.actions.getUsers({
          users,
          pagination: {
            ...pagination,
            count: total,
          },
          filter,
          params,
        }),
      );
    } else {
      dispatch(slice.actions.error());
    }
  };

export const getUser =
  ({ id }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { user, success } = await userApi.getUser({ id });
    if (!success) {
      dispatch(slice.actions.error());
    } else {
      dispatch(slice.actions.getUser(user));
    }
  };

export const updateUser =
  ({ user, userId, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await userApi.updateUser(userId, user);
    if (success) {
      onComplete({ success });
      dispatch(slice.actions.updateUser({ user, userId }));
    } else {
      onError('Cannot update user');
    }
  };

export const createUser =
  ({ user, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success, user: createdUser, message } = await userApi.createUser(user);
    if (success) {
      onComplete({ success, user: createdUser });
      dispatch(slice.actions.createUser({ user: createdUser }));
    } else {
      onError(message);
    }
  };

export const createUserKpi =
  ({ userId, kpi, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await userApi.createUserKpi(userId, kpi);
    if (success) {
      onComplete({ success, kpi });
      // dispatch(slice.actions.createUser({ user: createdUser }));
    } else {
      onError('Cannot update user');
    }
  };

export const updateUserKpi =
  ({ userId, kpiId, kpi, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await userApi.updateUserKpi(userId, kpiId, kpi);
    if (success) {
      onComplete({ success, kpi });
      // dispatch(slice.actions.createUser({ user: createdUser }));
    } else {
      onError('Cannot update user');
    }
  };

export const getOrders =
  ({
    userId,
    filter,
    pagination,
    params,
  }: {
    userId: string;
    filter?: CustomerFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: CustomerParams;
  }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      status: params.status,
      exceptRole: 'client',
    };
    dispatch(slice.actions.setOrderStatus('loading'));
    const { orders, total, overview, success } = await userApi.getOrdersCreatedByUserId(userId, filterValues);
    if (success) {
      dispatch(
        slice.actions.getUserOrder({
          orders,
          total,
          userId,
          overview,
          pagination: {
            ...pagination,
            count: total,
          },
        }),
      );
      dispatch(slice.actions.setOrderStatus('success'));
    } else {
      dispatch(slice.actions.setOrderStatus('error'));
    }
  };
export const getAddress =
  (userId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setAddressStatus('loading'));
    const { addresses, success } = await userApi.getAddress(userId);
    if (success) {
      dispatch(slice.actions.getUserAddress({ address: addresses, userId }));
      dispatch(slice.actions.setAddressStatus('success'));
    } else {
      dispatch(slice.actions.setAddressStatus('error'));
    }
  };

export const getUserKpis =
  (userId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setUserKpiStatus('loading'));
    const { kpis, success } = await userApi.getKpis(userId);
    if (success) {
      dispatch(
        slice.actions.getUserKpis({
          kpis,
          userId,
          total: 0,
        }),
      );
      dispatch(slice.actions.setUserKpiStatus('success'));
    } else {
      dispatch(slice.actions.setUserKpiStatus('error'));
    }
  };

export const getUserKpi =
  (userId: string, month: number, year: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setUserKpiMonthStatus('loading'));
    const { kpi, success } = await userApi.getKpi(userId, month, year);
    if (success) {
      dispatch(
        slice.actions.getUserKpi({
          kpi,
          userId,
          month,
          year,
        }),
      );
      dispatch(slice.actions.setUserKpiMonthStatus('success'));
    } else {
      dispatch(slice.actions.setUserKpiMonthStatus('error'));
      dispatch(
        slice.actions.getUserKpi({
          kpi: {} as unknown as IKpiTypeWithOrders,
          userId,
          year,
          month,
        }),
      );
    }
  };

export const deleteKPI =
  (kpiId: string, onSuccess: () => void, onError: () => void): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setUserKpiStatus('loading'));
    const { success } = await userApi.deleteKpiId(kpiId);
    if (success) {
      dispatch(slice.actions.deleteKpi(kpiId));
      dispatch(slice.actions.setUserKpiStatus('success'));
      onSuccess?.();
    } else {
      dispatch(slice.actions.setUserKpiStatus('error'));
      onError?.();
    }
  };

export const activeKPI =
  (kpiId: string, userId: string, onSuccess: () => void, onError: () => void): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { user } = getState();
    dispatch(slice.actions.setUserKpiStatus('loading'));
    const { success } = await userApi.activeKpi(kpiId, userId);
    if (success) {
      dispatch(slice.actions.setUserKpiStatus('success'));
      const {
        targetKpi: { year, month },
      } = user;
      dispatch(getUserKpis(userId));
      dispatch(getUserKpi(userId, month, year));
      onSuccess?.();
    } else {
      dispatch(slice.actions.setUserKpiStatus('error'));
      onError?.();
    }
  };

export default slice;
