import { Notification } from 'src/types/notification';

const apiRoot = process.env.REACT_APP_NOTIFICATION_API_URL;
class FcmApi {
  async subscribeTopic(token: string, userId: string): Promise<{ success: boolean; message?: string }> {
    try {
      await fetch(`${apiRoot}/api/notifications/subscribe-to-topics`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ userId, token }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        success: true,
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }

  async getNotification(
    userId: string,
    limit: number,
    offset: number,
    status: string,
  ): Promise<{ success: boolean; notifications: Notification[]; message?: string }> {
    const statusQuery = status === 'all' ? '' : `&status=${status}`;
    try {
      const response = await fetch(
        `${apiRoot}/api/notifications/user/${userId}?limit=${limit}&offset=${offset}${statusQuery}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const { success, data } = await response.json();
      return {
        success,
        notifications: data?.notifications || [],
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        notifications: [],
        message: err.toString(),
      };
    }
  }

  async getNotificationCount(userId: string): Promise<any> {
    try {
      const response = await fetch(`${apiRoot}/api/notifications/user/${userId}/count`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { success, data } = await response.json();

      return {
        success,
        total: parseInt(data?.total, 10) || 0,
        unread: parseInt(data?.unread, 10) || 0,
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }

  async readAllNotification(userId: string): Promise<any> {
    try {
      await fetch(`${apiRoot}/api/notifications/user/${userId}/mark-all-read`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        success: true,
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }

  async readNotification(notificationId: string): Promise<any> {
    try {
      await fetch(`${apiRoot}/api/notifications/${notificationId}/read`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        success: true,
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }
}

export const fcmApi = new FcmApi();
