import { isNil, omitBy } from 'lodash';
import { User, UserByPosition } from '../types/users';
import { IOrder, Order, OrderOverviewType, OrderParams } from '../types/order';
import { IAddress } from '../types/address';
import { CreatingKpi, IKpiType, IKpiTypeWithOrders } from 'src/types/kpi';
import axios from 'axios';
import { API_URL } from './api';

const apiRoot = process.env.REACT_APP_API_URL;
class UserApi {
  async getAxiosList(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
    roles?: string;
    position?: string;
  }): Promise<{ users?: User[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.USER}?${query}`);
    if (response?.status === 200) {
      const { users, total } = response?.data?.data?.attributes;
      return { users, total, success: true };
    }
    return null;
  }

  async getAxiosStaffListByPosition(filter?: {
    position: string;
    limit?: number;
    offset?: number;
  }): Promise<{ users?: UserByPosition[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.USER}/orders-by-position?${query}`);
    if (response?.status === 200) {
      const { users, total } = response?.data?.data?.attributes;
      return { users, total, success: true };
    }
    return null;
  }

  async getOrdersCreatedByUserId(
    userId: string,
    filter?: {
      searchValue?: string;
      status?: string;
      orderBy?: string;
      orderDirection?: 'asc' | 'desc';
      limit: number;
      offset: number;
      roles?: string;
    },
  ): Promise<{ overview?: OrderOverviewType; orders?: IOrder[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.ORDER}/user/${userId}/created?${query}`);
    if (response?.status === 200) {
      const { orders: resOrders } = response?.data?.data?.attributes;
      const { orders, total, overview } = resOrders;
      return { orders, overview, total, success: true };
    }
    return null;
  }

  async getUser({ id }: { id: string }): Promise<{ user?: User; success: boolean }> {
    try {
      const apiResponse = await axios.get(`${apiRoot}/api/users/${id}`);
      const user = apiResponse?.data?.data?.attributes?.user;
      if (user) {
        return {
          user,
          success: true,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteUser(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      if (success) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async deactiveAxiosUser(id: string): Promise<{ data?: User; success: boolean; message?: string }> {
    try {
      const response = await axios.delete(`${API_URL.USER}/${id}`);

      return { success: true, data: response.data?.data?.attributes?.user };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async superDeleteUser(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/super-delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      if (success) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async approveUser(id: string): Promise<{ user?: User; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/approve/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const { user } = apiResponse?.data?.attributes;
      if (user) {
        return {
          success: true,
          user,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async approveAxiosUser(id: string): Promise<{ data?: User; success: boolean; message?: string }> {
    try {
      const response = await axios.patch(`${API_URL.USER}/approve/${id}`);

      return { success: true, data: response.data?.data?.attributes?.user };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateUser(id: string, user: User): Promise<{ user?: User; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${apiRoot}/api/users/${id}`, user);

      const { user: newUser } = apiResponse?.data?.data?.attributes;
      if (user) {
        return {
          success: true,
          user: newUser,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createUser(user: User): Promise<{ user?: User; success: boolean; message?: string }> {
    try {
      const res = await fetch(`${apiRoot}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
        body: JSON.stringify(user),
      });
      const apiResponse = await res.json();
      const newUser = apiResponse?.data?.attributes?.user;
      if (newUser) {
        return {
          success: true,
          user: newUser,
        };
      }
      if (apiResponse.statusCode === 400) {
        return {
          success: false,
          message: apiResponse.message,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
    return {
      success: false,
    };
  }

  async createAxiosUser(user: User): Promise<{ data?: User; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.USER}`, user);

      return { success: true, data: response.data?.data?.attributes?.user };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateAxiosUser(id: string, user: User): Promise<{ data?: User; success: boolean; message?: string }> {
    try {
      const response = await axios.patch(`${API_URL.USER}/${id}`, user);

      return { success: true, data: response.data?.data?.attributes?.user };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async deleteAxiosUser(id: string): Promise<{ data?: User; success: boolean; message?: string }> {
    try {
      const response = await axios.delete(`${API_URL.USER}/super-delete/${id}`);

      return { success: true, data: response.data?.data?.attributes?.user };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getAxiosUserById(id: string): Promise<{ data?: User; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.USER}/${id}`);

      return { success: true, data: response.data?.data?.attributes?.user };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getOrders(
    userId: string,
    filter?: {
      status?: string;
      orderBy?: string;
      orderDirection?: 'asc' | 'desc';
      limit: number;
      offset: number;
    },
  ): Promise<{ orders?: IOrder[]; total?: number; overview?: OrderOverviewType; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const res = await fetch(`${apiRoot}/api/users/${userId}/orders?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const { orders, total } = apiResponse?.data?.attributes;
      return { orders, total, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getAddress(userId: string): Promise<{ addresses?: IAddress[]; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/address`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const addressesRaw = apiResponse?.data;
      const addresses = addressesRaw.map((address) => ({ id: address.id, ...address.attributes }));
      return { addresses, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getAxiosAddressByUserId(id: string): Promise<{ data?: IAddress[]; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.USER}/${id}/address`);

      return {
        success: true,
        data: response.data?.data?.map((address) => ({ id: address.id, ...address.attributes })),
      };
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getKpis(userId: string): Promise<{ kpis?: IKpiType[]; success: boolean }> {
    try {
      const apiResponse = await axios.get(`${apiRoot}/api/users/${userId}/kpis`);
      const kpisRaw = apiResponse?.data?.data;
      const kpis = kpisRaw.map((kpi) => ({ id: kpi.id, ...kpi.attributes }));
      return { kpis, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getKpi(userId: string, month: number, year: number): Promise<{ kpi?: IKpiTypeWithOrders; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpi?year=${year}&month=${month}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const kpi = apiResponse?.data;
      return { kpi: { id: kpi.id, ...kpi.attributes }, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createUserKpi(
    userId: string,
    creatingKpi: CreatingKpi,
  ): Promise<{ kpi?: IKpiTypeWithOrders; success: boolean }> {
    try {
      const apiResponse = await axios.post(`${apiRoot}/api/users/${userId}/kpi`, JSON.stringify(creatingKpi));
      const kpi = apiResponse?.data;
      return { kpi: { id: kpi.id, ...kpi.attributes }, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async updateUserKpi(
    userId: string,
    kpiId: string,
    creatingKpi: CreatingKpi,
  ): Promise<{ kpi?: IKpiTypeWithOrders; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${apiRoot}/api/users/${userId}/kpi/${kpiId}`, JSON.stringify(creatingKpi));
      const kpi = apiResponse?.data;
      return { kpi: { id: kpi.id, ...kpi.attributes }, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteKpiId(kpiId: string): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/kpis/${kpiId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      return { success: true };
    } catch (err) {
      console.error('[KPI Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async activeKpi(kpiId: string, userId: string): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpi/${kpiId}/active`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      await res.json();
      return { success: true };
    } catch (err) {
      console.error('[KPI Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getAxiosListOrderByUser(
    id: string,
    params?: OrderParams,
  ): Promise<{ orders?: Order[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(params, isNil)).toString();

    const response = await axios.get(`${API_URL.USER}/${id}/orders?${query}`);
    if (response?.status === 200) {
      const { orders, total } = response?.data?.data?.attributes;
      return { orders, total, success: true };
    }
    return null;
  }

  async getAxiosOrderOverviewByUser(id: string): Promise<{
    overview?: {
      total?: string;
      totalAmount?: number;
      totalAmountVat?: number;
    };
    success: boolean;
  }> {
    const response = await axios.get(`${API_URL.USER}/${id}/orders/overview`);
    if (response?.status === 200) {
      const overview = response?.data?.data?.attributes;
      return { overview, success: true };
    }
    return null;
  }
}

export const userApi = new UserApi();
