import axios from 'axios';
import { isNil, omitBy, get } from 'lodash';
import { API_URL } from './api';
import { Job } from 'src/types/job';

class JobApi {
  async getList(filter?: {
    status?: string;
    limit: number;
    offset: number;
  }): Promise<{ data?: Job[]; total?: number; success: boolean }> {
    try {
      if (filter.status === 'all') {
        filter.status = undefined;
      }
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const apiResponse = await axios.get(`${API_URL.JOB}?${query}`);
      const data = get(apiResponse, ['data', 'jobs'], []);
      const total = apiResponse?.data?.total ?? 0;
      return { data, total, success: true };
    } catch (err) {
      console.error('[Postcode Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const jobApi = new JobApi();
