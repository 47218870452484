import { isNil, omitBy } from 'lodash';
import { Supplier } from '../types/supplier';
import axios from 'axios';
import { API_URL } from './api';
import { Product } from 'src/types/product';

class SupplierApi {
  async getList(filter?: {
    searchValue?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ supplier?: Supplier[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.SUPPLIER}?${query}`);
    if (response?.status === 200) {
      const { supplier, total } = response?.data?.data?.attributes;
      return { supplier, total, success: true };
    }
    return null;
  }

  async getProductListBySupplierId(filter?: {
    id: string;
    searchValue?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ data?: Product[]; success: boolean; total?: number }> {
    try {
      const apiResponse = await axios.get(`${API_URL.SUPPLIER}/product/${filter.id}`);
      const { product: data, total } = apiResponse?.data?.data?.attributes;
      if (data) {
        return {
          data,
          total,
          success: true,
        };
      }
    } catch (err) {
      console.error('[Supplier Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getSupplierById(id: string): Promise<{ data?: Supplier; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.SUPPLIER}/${id}`);

      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id,
          postcode: response.data?.data?.attributes?.postcode.toString(),
        },
      };
    } catch (err) {
      console.error('[Supplier Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async deleteSupplier(id: string): Promise<{ success: boolean }> {
    try {
      const res = await axios.delete(`${API_URL.SUPPLIER}/${id}`);
      const { id: resId } = res?.data?.data;
      if (resId) {
        return {
          success: true,
        };
      }
    } catch (err) {
      console.error('[Supplier Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async updateSupplier(id: string, supplier: Supplier): Promise<{ supplier?: Supplier; success: boolean }> {
    try {
      const res = await axios.patch(`${API_URL.SUPPLIER}/${id}`, supplier);

      return {
        success: true,
        supplier: {
          ...res?.data?.data?.attributes,
          id,
        },
      };
    } catch (err) {
      console.error('[Supplier Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createSupplier(input: Supplier): Promise<{ data?: Supplier; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.SUPPLIER}`, input);

      return { success: true, data: { ...response.data?.data?.attributes, id: response.data?.data?.id } };
    } catch (err) {
      console.error('[Supplier Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}

export const supplierApi = new SupplierApi();
