import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fcmApi } from 'src/api/notificationApi';
import { AppThunk } from 'src/store';
import { Notification } from 'src/types/notification';

type ModuleState = 'pending' | 'loading' | 'success' | 'fail';
export type NotificationState = {
  open: boolean;
  hasMore: boolean;
  total: number;
  unread: number;
  notifications: Notification[];
  isLoadingMore: boolean;
  state: ModuleState;
  pagination: {
    limit: number;
    offset: number;
    status: string;
  };
};

const initialState: NotificationState = {
  open: false,
  hasMore: false,
  total: 0,
  unread: 0,
  notifications: [],
  isLoadingMore: false,
  state: 'pending',
  pagination: {
    limit: 15,
    offset: 0,
    status: 'all',
  },
};

const dialogSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setState(state, action: PayloadAction<ModuleState>) {
      state.state = action.payload;
    },
    setCount(state, action: PayloadAction<{ unread: number; total: number }>) {
      state.total = action.payload.total;
      state.unread = action.payload.unread;
    },
    addNotifications(state, action: PayloadAction<Notification[]>) {
      const notifications = action.payload;
      const newNotifications = [...state.notifications, ...notifications];
      state.notifications = newNotifications;
      state.hasMore = newNotifications.length > 0 ? newNotifications.length < state.total : false;
    },
    updatePagination(state, action: PayloadAction<{ limit: number; offset: number; status: string }>) {
      state.pagination.limit = action.payload.limit;
      state.pagination.offset = action.payload.offset;
      state.pagination.status = action.payload.status;
    },
    setLoadMoreState(state, action: PayloadAction<boolean>) {
      state.isLoadingMore = action.payload;
    },
    cleanUpModule(state) {
      state.notifications = initialState.notifications;
      state.pagination = initialState.pagination;
      state.state = initialState.state;
      state.hasMore = false;
      state.isLoadingMore = false;
    },
    readAllNotificationAction(state) {
      const notifications = state.notifications.map((notification) => ({ ...notification, isRead: true }));
      console.log(notifications);
      state.notifications = notifications;
      state.unread = 0;
    },
    readNotificationAction(state, action: PayloadAction<string>) {
      const notificationId = action.payload;
      const notification = state.notifications.find((item) => item.id === notificationId);
      if (notification && !notification.isRead) {
        const notifications = state.notifications.map((item) =>
          item.id !== notificationId ? { ...item } : { ...item, isRead: true },
        );
        state.notifications = notifications;
        state.unread = state.unread > 0 ? state.unread - 1 : 0;
      }
    },
  },
});

export const {
  setOpen,
  setState,
  addNotifications,
  updatePagination,
  setCount,
  setLoadMoreState,
  cleanUpModule,
  readAllNotificationAction,
  readNotificationAction,
} = dialogSlice.actions;

export const { reducer } = dialogSlice;

export const getCount =
  ({ userId }: { userId: string }): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      const { total, unread } = await fcmApi.getNotificationCount(userId);
      dispatch(setCount({ total, unread }));
    } catch (error) {
      console.error(error);
    }
  };

export const getNotifications =
  ({ userId, limit, offset, status }: { userId: string; limit: number; offset: number; status: string }): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      dispatch(setState('loading'));
      dispatch(updatePagination({ limit, offset, status }));
      const { notifications } = await fcmApi.getNotification(userId, limit, offset, status);
      dispatch(addNotifications(notifications));
      dispatch(setState('success'));
    } catch (error) {
      console.error(error);
      dispatch(setState('fail'));
    }
  };

export const getMoreNotifications =
  ({ userId, limit, offset, status }: { userId: string; limit: number; offset: number; status: string }): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      dispatch(setLoadMoreState(true));
      dispatch(updatePagination({ limit, offset, status }));
      const { notifications } = await fcmApi.getNotification(userId, limit, offset, status);
      dispatch(addNotifications(notifications));
      dispatch(setLoadMoreState(false));
    } catch (error) {
      console.error(error);
      dispatch(setLoadMoreState(false));
    }
  };

export const readNotifications =
  ({ userId }: { userId: string }): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      dispatch(setLoadMoreState(true));
      const { success } = await fcmApi.readAllNotification(userId);
      if (success) {
        dispatch(readAllNotificationAction());
      }
      dispatch(setLoadMoreState(false));
    } catch (error) {
      console.error(error);
      dispatch(setLoadMoreState(false));
    }
  };

export const readNotification =
  ({ notificationId, onSuccess }: { notificationId: string; onSuccess: () => void }): AppThunk =>
  async (dispatch): Promise<any> => {
    try {
      const { success } = await fcmApi.readNotification(notificationId);
      if (success) {
        dispatch(readNotificationAction(notificationId));
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadMoreState(false));
    }
  };

export default dialogSlice;
