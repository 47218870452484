import { matchPath } from 'react-router';
import { ROLES_BY_ROUTE } from 'src/routes/roles-by-route';

export const checkPermissionByRole = (currentPath: string, role: string) => {
  const findMatchPatch = Object.keys(ROLES_BY_ROUTE).find((pathname) =>
    matchPath(
      {
        path: currentPath,
        end: true,
      },
      pathname,
    ),
  );
  if (findMatchPatch) {
    const allowedRoles = ROLES_BY_ROUTE[findMatchPatch];
    return allowedRoles?.includes(role);
  }
  return true;
};
