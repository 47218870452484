import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { warehouseAPI } from 'src/api/warehouseAPI';
import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';

import { AppThunk } from 'src/store';
import { DataPaging, IPagination } from 'src/types/data';
import {
  WarehouseArea,
  WarehouseFilterValues,
  WarehouseFloor,
  WarehouseFloorCreatePayload,
  WarehouseParams,
} from 'src/types/warehouse';

export type WarehouseState = {
  data: DataPaging<WarehouseFloor[]>;
  params: WarehouseParams;
  filter: WarehouseFilterValues;
  currentFloor: WarehouseFloor | null;
};

export const initialState: WarehouseState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
  currentFloor: null,
};

const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    setDataWarehouse(
      state,
      action: PayloadAction<{ data: DataPaging<WarehouseFloor[]> | null; params: WarehouseParams; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataWarehouse(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
    setCurrentWarehouseFloor(state, action: PayloadAction<WarehouseFloor | null>) {
      state.currentFloor = action.payload;
    },
  },
});
export const {
  reducer,
  actions: { setDataWarehouse, resetDataWarehouse, setCurrentWarehouseFloor },
} = warehousesSlice;

export default warehousesSlice;

export const getListWarehouse =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: WarehouseFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: WarehouseParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      status: params.status,
    };
    const data = await warehouseAPI.getListWarehouseFloor(filterValues as any);

    if (data) {
      dispatch(
        setDataWarehouse({
          data: {
            data: data.warehouseFloor,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

export const createWarehouseFloor =
  ({
    values,
    onSuccess,
    onError,
  }: {
    values: WarehouseFloorCreatePayload;
    onSuccess?: (res: WarehouseArea) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await warehouseAPI.createFloor(values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };
export const updateWarehouseFloor =
  ({
    values,
    onSuccess,
    onError,
    id,
  }: {
    id: string;
    values: WarehouseFloorCreatePayload;
    onSuccess?: (res: WarehouseFloor) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await warehouseAPI.updateFloor(id, values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };
export const getWarehouseFloorById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await warehouseAPI.getFloorById(id);

      const { data } = response;

      if (response.success) {
        disptach(setCurrentWarehouseFloor(data));
      }
    } catch (error) {
      console.log('error', error);
    }
  };
