import axios from 'axios';
import { isNil, omitBy } from 'lodash';
import { WarehouseArea, WarehouseFloor, WarehouseFloorCreatePayload } from 'src/types/warehouse';
import { API_URL } from './api';

class WarehouseAPI {
  async getListWarehouseFloor(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ warehouseFloor?: WarehouseFloor[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.WAREHOUSE_FLOOR}?${query}`);
    if (response?.status === 200) {
      const { warehouseFloor, total } = response?.data?.data?.attributes;
      return { warehouseFloor, total, success: true };
    }
    return null;
  }

  async createFloor(
    payload: WarehouseFloorCreatePayload,
  ): Promise<{ data?: WarehouseFloor; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.WAREHOUSE_FLOOR}`, payload);

      return { success: true, data: response.data?.data?.attributes };
    } catch (err) {
      console.error('[Warehouse Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateFloor(
    id: string,
    payload: WarehouseFloorCreatePayload,
  ): Promise<{ data?: WarehouseFloor; success: boolean; message?: string }> {
    try {
      const response = await axios.patch(`${API_URL.WAREHOUSE_FLOOR}/${id}`, payload);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getFloorById(id: string): Promise<{ data?: WarehouseFloor; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.WAREHOUSE_FLOOR}/${id}`);

      return { success: true, data: { ...response.data?.data?.attributes, id } };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}

export const warehouseAPI = new WarehouseAPI();
