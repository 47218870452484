import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { jobApi } from 'src/api/job';
import type { AppThunk } from 'src/store';
import { Filter, Job } from 'src/types/job';

interface JobState {
  jobs: Job[];
  status: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  targetId: string;
  total: number;
  filter: Filter;
  pageKey?: Record<string, unknown>;
}

const initialState: JobState = {
  jobs: [],
  filter: {
    limit: 10,
    page: 0,
    sort: 'startCode|asc',
    status: 'all',
  },
  status: 'idle',
  total: 0,
  targetId: '',
};

const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    loading(state: JobState): void {
      state.status = 'loading';
    },
    error(state: JobState, action: PayloadAction): void {
      state.status = 'error';
    },
    getJobList(state: JobState, action: PayloadAction<{ data: Job[]; total: number }>): void {
      const { data, total } = action.payload;
      state.status = 'success';
      state.total = total;
      state.jobs = data;
    },
    updateFilter(state: JobState, action: PayloadAction<Record<string, string>>): void {
      const filter = action.payload;
      state.filter = {
        ...state.filter,
        ...filter,
      };
    },
  },
});

export const { reducer } = slice;

export const updateFilter =
  (filter): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateFilter(filter));
  };

export const getList =
  (filter?: { status?: string; limit: number; offset: number }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.loading());
    const { data, total, success } = await jobApi.getList(filter);
    if (success) {
      dispatch(slice.actions.getJobList({ data, total }));
    } else {
      dispatch(slice.actions.error());
    }
  };

export default slice;
