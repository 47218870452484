import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import {
  cleanUpModule,
  getMoreNotifications,
  getNotifications,
  readNotification,
  readNotifications,
  setOpen,
} from 'src/slices/notification';
import { useDispatch, useSelector } from 'src/store';
import EmptyNotification from './Empty';
import NotificationItem from './Item';
import { useTranslation } from 'react-i18next';

const NotificationList = () => {
  const {
    notifications,
    state: moduleState,
    isLoadingMore,
    hasMore,
    pagination,
    unread,
  } = useSelector((state) => state.notification);
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState('all');
  const dispatch = useDispatch();
  const loading = useMemo(() => moduleState === 'loading' || isLoadingMore, [moduleState, isLoadingMore]);
  const listRef = useRef(null);
  const fetchNotifications = () => {
    if (loading) return;
    dispatch(
      getNotifications({
        userId: user?.id,
        limit: 15,
        offset: 0,
        status,
      }),
    );
  };

  const fetchMoreNotification = () => {
    dispatch(
      getMoreNotifications({
        userId: user?.id,
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
        status,
      }),
    );
  };

  useEffect(() => {
    fetchNotifications();
    return () => {
      dispatch(cleanUpModule());
    };
  }, []);

  const handleClickNotification = (id: string) => {
    dispatch(
      readNotification({
        notificationId: id,
        onSuccess: () => {
          const notification = notifications.find((item) => item.id === id);
          const { type, orderId } = notification?.data || {};
          if (type === 'order') {
            navigate(`/orders/${orderId as string}/detail`);
            setTimeout(() => {
              dispatch(setOpen(false));
            }, 0);
          }
        },
      }),
    );
  };

  const handleReadAll = () => {
    dispatch(readNotifications({ userId: user?.id }));
  };

  const handleUpdateFilterStatus = (e) => {
    const newStatus = e.target.value;
    dispatch(cleanUpModule());
    setStatus(newStatus);
    dispatch(
      getNotifications({
        userId: user?.id,
        limit: 15,
        offset: 0,
        status: newStatus,
      }),
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        mb: 2,
      }}
      ref={listRef}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel>{t('notification-status')}</InputLabel>
          <Select value={status} onChange={handleUpdateFilterStatus} label="Trạng thái">
            <MenuItem value="all">{t('notification-status-all')}</MenuItem>
            <MenuItem value="read">{t('notification-status-read')}</MenuItem>
            <MenuItem value="unread">{t('notification-status-unread')}</MenuItem>
          </Select>
        </FormControl>
        <Button variant="text" color="primary" disabled={unread === 0} onClick={handleReadAll}>
          {t('notification-read-all')}
        </Button>
      </Box>
      {notifications.length > 0 && (
        <List>
          {notifications.map((notification, index) => (
            <div key={notification.id}>
              <NotificationItem notification={notification} onClick={handleClickNotification} />
              {index < notifications.length - 1 && <Divider sx={{ marginLeft: 2, marginRight: 2 }} />}
            </div>
          ))}
        </List>
      )}
      {moduleState === 'success' && notifications.length === 0 && <EmptyNotification />}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && hasMore && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1, marginBottom: 1 }}>
          <Button variant="text" color="primary" onClick={fetchMoreNotification}>
            {t('notification-load-more')}
          </Button>
        </Box>
      )}
      {!hasMore && notifications.length > 0 && moduleState === 'success' && !loading && (
        <Box sx={{ textAlign: 'center', marginTop: 1, marginBottom: 1 }}>
          <Typography variant="body2">{t('notification-no-more')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationList;
