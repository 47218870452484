import { isNil, omitBy } from 'lodash';
import { PackagingUnit } from 'src/types/packagingUnit';
import axios from 'axios';
import { API_URL } from './api';

class PackagingUnitApi {
  async getList(filter?: {
    searchValue?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ packagingUnit?: PackagingUnit[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.PACKAGING_UNIT}?${query}`);
    if (response?.status === 200) {
      const { packagingUnit, total } = response?.data?.data?.attributes;
      return { packagingUnit, total, success: true };
    }
    return null;
  }

  async getPackagingUnitById(id: string): Promise<{ data?: PackagingUnit; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.PACKAGING_UNIT}/${id}`);

      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id,
        },
      };
    } catch (err) {
      console.error('[PackagingUnit Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async deletePackagingUnit(id: string): Promise<{ success: boolean }> {
    try {
      const res = await axios.delete(`${API_URL.PACKAGING_UNIT}/${id}`);
      const { id: resId } = res?.data?.data;
      if (resId) {
        return {
          success: true,
        };
      }
    } catch (err) {
      console.error('[PackagingUnit Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async updatePackagingUnit(
    id: string,
    packagingUnit: PackagingUnit,
  ): Promise<{ packagingUnit?: PackagingUnit; success: boolean }> {
    try {
      const res = await axios.patch(`${API_URL.PACKAGING_UNIT}/${id}`, packagingUnit);

      return {
        success: true,
        packagingUnit: {
          ...res?.data?.data?.attributes,
          id,
        },
      };
    } catch (err) {
      console.error('[PackagingUnit Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createPackagingUnit(
    input: PackagingUnit,
  ): Promise<{ data?: PackagingUnit; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.PACKAGING_UNIT}`, input);

      return { success: true, data: { ...response.data?.data?.attributes, id: response.data?.data?.id } };
    } catch (err) {
      console.error('[PackagingUnit Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}

export const packagingUnitApi = new PackagingUnitApi();
