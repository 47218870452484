import { Box, Divider, Drawer, IconButton, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { FC, useEffect } from 'react';
import useSettings from 'src/hooks/useSettings';
import { getCount, setOpen } from 'src/slices/notification';
import { useDispatch, useSelector } from 'src/store';
import NotificationList from './notification';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';

const NotificationDrawer: FC = () => {
  const { open } = useSelector((state) => state.notification);
  const { isMobile } = useSettings();
  const { t } = useTranslation();
  const { user } = useAuth();
  useEffect(() => {
    user?.id && dispatch(getCount({ userId: user?.id }));
  }, [open, user?.id]);
  const dispatch = useDispatch();
  const handleClose = (): void => {
    dispatch(setOpen(false));
  };

  return (
    <>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: isMobile ? '100vw' : 500,
          },
        }}
      >
        <Box
          sx={{
            height: 64,
            display: 'flex',
            alignItems: 'center',
            p: 2,
            justifyContent: 'space-between',
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {t('notifications')}
          </Typography>
          <IconButton onClick={handleClose}>
            <ArrowForward />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            height: 'calc(100vh - 65px)',
          }}
        >
          <NotificationList />
        </Box>
      </Drawer>
    </>
  );
};

export default NotificationDrawer;
