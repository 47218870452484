// If you want to allow functions to have different return behavior depending on code branching, then it is safe to disable this rule.: https://eslint.org/docs/latest/rules/consistent-return
/* eslint-disable consistent-return */

/* eslint-disable prettier/prettier */
import { Dispatch, SetStateAction } from 'react';
import { get, isNil } from 'lodash';
import toast from 'react-hot-toast';

import { megaCateApi } from 'src/api/megaCateApi';
import { NODE_ENV, LOCALE_CODES, DEFAULT_CURRENCY_CODE, DEFAULT_LOCALE, defaultAddressCoords } from 'src/constants';
import { IResponseItem } from 'src/types/address';
import { Product } from 'src/types/product';
import { mapyConfig } from 'src/config';

export const renderPrice = (
  inputNumber: number | string,
  {
    fixedNumber = 2,
    currencyCode = DEFAULT_CURRENCY_CODE,
    locale = DEFAULT_LOCALE,
  }: { fixedNumber?: number; currencyCode?: string; locale?: string } = {},
) => {
  const numb = Number(inputNumber);
  if (isNaN(numb)) return null;
  const formatObj: Intl.NumberFormatOptions = {
    maximumFractionDigits: fixedNumber,
    minimumFractionDigits: 0,
  };

  if (currencyCode) {
    formatObj.currency = currencyCode;
    formatObj.style = 'currency';
  }
  const numberVal = new Intl.NumberFormat(locale, formatObj).format(numb);
  return numberVal;
};

export async function runSuggest(
  lang: string,
  query: string,
  setSuggestions: Dispatch<SetStateAction<IResponseItem[]>>,
) {
  try {
    const url = new URL(`${mapyConfig.apiURL}suggest`);

    url.searchParams.set('apikey', mapyConfig.apiKey);
    url.searchParams.set('lang', lang);
    url.searchParams.set('query', query);
    url.searchParams.set('type', 'regional.address');
    url.searchParams.set('locality', 'cz');

    const response = await fetch(url.toString(), {
      mode: 'cors',
    });
    const json = await response.json();

    if (json?.items?.length) {
      setSuggestions(json.items);
    } else {
      setSuggestions([]);
    }
  } catch (ex) {
    setSuggestions([]);
  }
}

export const handleMapAutocomplete = ({
  selectedAddress: value,
  postcode,
}: {
  selectedAddress: IResponseItem;
  postcode: string;
}) => {
  if (!value) {
    return {
      address: '',
      addressCoords: defaultAddressCoords,
    };
  }

  const selectedAddress = postcode
    ? `${value.name} - ${
        value.regionalStructure.find((region) => region.type === 'regional.municipality')?.name || ''
      } - ${value.regionalStructure.find((region) => region.type === 'regional.country')?.name || ''}`
    : '';

  return {
    address: selectedAddress,
    postcode,
    addressCoords: JSON.stringify(value.position),
  };
};

export const mapValueToLocale = ({ input, output, fieldName }: { output: any; input: any; fieldName: string }) => {
  for (const localeCode of LOCALE_CODES) {
    output[`${localeCode}_${fieldName}`] = input[localeCode] || '';
  }
  return output;
};

export const combineLocaleValue = ({ input, fieldName }: { input: any; fieldName: string }) => {
  const result = {};
  for (const localeCode of LOCALE_CODES) {
    result[localeCode] = input[`${localeCode}_${fieldName}`];
  }
  return JSON.stringify(result);
};

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getLocaleByFieldName(item, fieldName, locale) {
  let content = item?.[fieldName] || '';
  if (isJsonString(isJsonString)) {
    content = JSON.parse(content)[locale];
  }
  return content;
}

const SCANNER_WIDTH = 300;
const LINE_WIDTH = 50;

export const handleDrawRectangle = (id: string, { width, height }: { width: number; height: number }) => {
  const canvas = document.getElementById(id) as HTMLCanvasElement;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = '#00000';
  ctx.globalAlpha = 0.2;
  let scannerDimension = width - 100;
  if (scannerDimension < 0) return;
  scannerDimension = scannerDimension > SCANNER_WIDTH ? SCANNER_WIDTH : scannerDimension;

  const leftPadding = (width - scannerDimension) / 2;
  const left = leftPadding > 0 ? leftPadding : 0;
  const topPadding = (height - scannerDimension) / 2;
  const top = topPadding > 0 ? topPadding : 0;
  ctx.fillRect(0, 0, width, height);
  ctx.clearRect(left, top, scannerDimension, scannerDimension);
  ctx.strokeStyle = '#00000';
  ctx.lineWidth = 3;
  ctx.globalAlpha = 1;
  ctx.beginPath();
  ctx.moveTo(left, top);
  ctx.lineTo(left + LINE_WIDTH, top);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left, top);
  ctx.lineTo(left, top + LINE_WIDTH);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left + scannerDimension, top);
  ctx.lineTo(left + scannerDimension, top + LINE_WIDTH);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left + scannerDimension, top);
  ctx.lineTo(left + scannerDimension - LINE_WIDTH, top);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left + scannerDimension, top + scannerDimension);
  ctx.lineTo(left + scannerDimension - LINE_WIDTH, top + scannerDimension);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left + scannerDimension, top + scannerDimension);
  ctx.lineTo(left + scannerDimension, top + scannerDimension - LINE_WIDTH);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left, top + scannerDimension);
  ctx.lineTo(left, top + scannerDimension - LINE_WIDTH);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(left, top + scannerDimension);
  ctx.lineTo(left + LINE_WIDTH, top + scannerDimension);
  ctx.stroke();
};

export async function uploadFile({ file, fileType }: { file: File; fileType?: string }) {
  if (file) {
    const body = new FormData();
    body.append('file', file);
    const response = await megaCateApi.uploadImageApi(body, fileType);
    const fileUrl = get(response, ['data', 'fileUrl']);
    if (!fileUrl) {
      return;
    }
    return fileUrl;
  }
  return undefined;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function checkFile(file: File, checkingFileType = 'image/') {
  if (!file) return false;
  const fileType = get(file, ['type']);
  const fileSize = get(file, ['size'], 0);

  const mbSize = fileSize / 1024 / 1024;

  if (!fileType.includes?.(checkingFileType) || fileType.includes?.('svg')) {
    toast.error(`File type not support!`);
    return false;
  }

  if (NODE_ENV === 'production') {
    if (mbSize > 500) {
      toast.error('File size is too large!');
      return false;
    }
  } else if (mbSize > 50) {
    toast.error('File size is too large!');
    return false;
  }
  return true;
}

export function prepareNumberVal(value) {
  if (isNil(value)) {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }

  return +value?.replaceAll(/([^0-9])/g, '') || 0;
}

export function validateInteger(value: string, rootValue: string) {
  if (/([^0-9])/.test(value)) {
    toast.error('Value is not valid');
    value = rootValue;
    return false;
  }
  if (+value < 0) {
    toast.error('Value cannot be negative');
    value = rootValue;
    return false;
  }
  return true;
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function distanceInKmBetweenEarthCoordinates({
  sourceCoords,
  destinationCoords,
}: {
  sourceCoords: string;
  destinationCoords: string;
}) {
  if (!isJsonString(sourceCoords) || !isJsonString(destinationCoords)) {
    return 0;
  }

  const add1Coords = JSON.parse(sourceCoords);
  let lat1 = add1Coords?.lat;
  const lon1 = add1Coords?.lon;
  const add2Coords = JSON.parse(destinationCoords);
  let lat2 = add2Coords?.lat;
  const lon2 = add2Coords?.lon;
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}

export const getTotalAmount = (row: Product) =>
  row.unitPrice * row.quantity * (+row[row.productUnit] || 1) +
  ((row.vat || 0) * row.unitPrice * row.quantity * (+row[row.productUnit] || 1)) / 100 -
  (row.discount || 0) * row.quantity * (+row[row.productUnit] || 1);
