import axios from 'axios';
import { isNil, omitBy } from 'lodash';
import { Order, OrderParams, OrderV2OverviewData, DraftOrderInput } from 'src/types/order';

import { API_URL } from './api';

class OrderAPI {
  async getList(params?: OrderParams): Promise<{ orders?: Order[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(params, isNil)).toString();

    const response = await axios.get(`${API_URL.ORDER}?${query}`);
    if (response?.status === 200) {
      const { orders, total } = response?.data?.data?.attributes;
      return { orders, total, success: true };
    }
    return null;
  }

  async getOrderOverView(params: {
    type: string;
  }): Promise<{ overview?: OrderV2OverviewData[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(params, isNil)).toString();

    const response = await axios.get(`${API_URL.ORDER}/v2/overview?${query}`);

    if (response?.data?.data?.attributes?.[params?.type]) {
      const overview = response?.data?.data?.attributes?.[params?.type];
      return { overview, success: true };
    }
    return null;
  }

  async copyOrder(id: string): Promise<{ total?: number; success: boolean }> {
    const response = await axios.post(`${API_URL.ORDER}/${id}/clone`);
    if (response?.data?.data?.id) {
      return { success: true };
    }
    return null;
  }

  async deleteOrder(id: string): Promise<{ total?: number; success: boolean }> {
    const response = await axios.delete(`${API_URL.ORDER}/${id}`);
    if (response?.data?.data?.attributes.success) {
      return { success: true };
    }
    return null;
  }

  async cancelOrder(id: string): Promise<{ total?: number; success: boolean }> {
    const response = await axios.delete(`${API_URL.ORDER}/${id}/cancel`);
    if (response?.data?.data?.attributes.success) {
      return { success: true };
    }
    return null;
  }

  async createAxiosOrder(order: DraftOrderInput): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.ORDER}`, order);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateAxiosOrder(
    id: string,
    order: DraftOrderInput,
  ): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.put(`${API_URL.ORDER}/${id}`, order);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateAxiosOrderStep({
    values,
    id,
    step,
  }: {
    id: string;
    values: {
      userId: string;
      status: string;
    };
    step: string;
  }): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.patch(`${API_URL.ORDER}/${id}/steps/${step}`, values);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getAxiosOrderById(id: string): Promise<{ data?: Order; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.ORDER}/${id}`);

      return { success: true, data: { ...response.data?.data?.attributes, id } };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async exportAxiosOrderById(
    id: string,
    type: 'pdf' | 'excel',
    orderNumber: number,
  ): Promise<{ success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.ORDER}/${id}/export?type=${type}`, {
        responseType: 'blob',
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `order-${orderNumber}.${type === 'pdf' ? 'pdf' : 'xlsx'}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return { success: true };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async forceFinishOrderById(id: string): Promise<{ success: boolean; message?: string }> {
    try {
      await axios.patch(`${API_URL.ORDER}/${id}/force-complete`);
      return { success: true };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}

export const orderAPIV2 = new OrderAPI();
